@import 'typography';
@import 'layout';

.tfl-personal-details-header,
.tfl-security-details-header {
  margin-bottom: 20px;
}

.tfl-card-group-wrapper {
  margin-bottom: 27px;
}
