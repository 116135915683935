@import '@tfl/component-library/build/assets/scss/common.scss';
@import '/src/assets/scss/mixin.scss';

.tfl-personal-details {
  @include card;
  padding: 24px 16px 16px 16px;
  margin-bottom: 24px;
  &--loading {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__alert {
    margin-bottom: 24px;
  }

  &__name,
  &__email,
  &__phone {
    margin-bottom: 24px;
    overflow-wrap: break-word;
    &-label {
      font-size: 18px;
      font-family: $johnston-medium;
    }
  }
}
