@import 'typography';
@import 'layout';

.tfl-tile--icon-link-out {
  white-space: nowrap;
}

.tfl-go-card-desktop {
  display: block;
}

.tfl-go-card-mobile {
  display: none;
}
