@import '@tfl/component-library/build/assets/scss/common.scss';
@import '/src/assets/scss/mixin.scss';

.tfl-offers-card {
  @include card;
  padding: 31px 16px 16px 16px;

  &--loading {
    height: 311px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__alert {
    margin-bottom: 24px;
  }

  &__tfl {
    &-tflDescription {
      margin-bottom: 40px;
    }
    &--yes {
      @include icon-tick-circle;
      padding-left: 42px;
      padding-top: 3px;
    }

    &--no {
      color: $tfl-black;
      @include icon-cross-in-circle(
        $circle-fill: $tfl-black,
        $cross-fill: white
      );
      padding-left: 42px;
      padding-top: 3px;
    }
  }

  &__train {
    &-trainDescription {
      margin-bottom: 40px;
    }
    &--yes {
      @include icon-tick-circle;
      padding-left: 42px;
      padding-top: 3px;
    }

    &--no {
      color: $tfl-black;
      @include icon-cross-in-circle(
        $circle-fill: $tfl-black,
        $cross-fill: white
      );
      padding-left: 42px;
      padding-top: 3px;
    }
  }

  .hidden-clip {
    clip: rect(1px, 1px, 1px, 1px);
    margin: 0;
    position: absolute;
  }
}
