@import '@tfl/component-library/build/assets/scss/common.scss';
@import '/src/assets/scss/mixin.scss';

.tfl-address-card {
  @include card;
  padding: 24px 16px 16px 16px;
  margin-bottom: 27px;

  &__address {
    margin-bottom: 24px;
    overflow-wrap: break-word;
  }

  &__alert {
    margin-bottom: 24px;
  }

  &--loading {
    height: 203px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
