@import '@tfl/component-library/build/assets/scss/common.scss';
@import '/src/assets/scss/mixin.scss';

.tfl-dashboard {
  &__card {
    @include card;
    height: 100%;
    display: grid;
    cursor: pointer;
    position: relative;

    &-header {
      font-family: $johnston-medium;
      font-size: 21px;
      padding: 0 13px 0 13px;
    }

    &-label-wrapper {
      margin-bottom: 40px;
      margin-top: 24px;
    }

    &-description {
      font-family: $johnston-light;
      font-size: 18px;
      padding: 0 13px 0 13px;
    }

    &-button {
      padding: 0 13px 16px 13px;
      align-self: flex-end;
    }

    &--icon-oyster {
      @include icon-oyster;
      background-position-x: center;
      box-shadow: none;
      background-size: contain;
      width: 100%;
      height: 0;
      padding-top: 50%;
    }

    &--icon-contactless {
      @include icon-contactless;
      background-position-x: center;
      box-shadow: none;
      background-size: contain;
      width: 100%;
      height: 0;
      padding-top: 50%;
    }
  }
}

.tfl-dashboard__card:hover .tfl-button--icon-right-chevron {
  background-color: $tfl-grey-30;
  color: $tfl-black;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}