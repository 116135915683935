@import '@tfl/component-library/build/assets/scss/common.scss';

body {
  font-family: $johnston-light;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $johnston-regular;
  }
  a {
    color: $tfl-black;
    text-decoration: underline;
    &:hover {
      color: $tfl-black;
      text-decoration: none;
    }
    &:visited {
      color: $tfl-black;
    }
  }
}

h3 {
  font-size: 1.16rem;
}
