@import '@tfl/component-library/build/assets/scss/common.scss';

.tfl-authentication-card {
  @include card;
  padding: 24px 16px 16px 16px;

  &__header {
    font-family: $johnston-medium;
  }

  &__text {
    padding-top: 16px;
    margin-bottom: 24px;
  }
}
