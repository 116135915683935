@import '@tfl/component-library/build/assets/scss/common.scss';

/*CSS for sticky footer*/
#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  .tfl-content {
    flex: 1;
    margin: auto;
    width: 100%;
  }
}

html {
  font-size: 18px;
}

body {
  margin: 0;

  /* fix for positioning of content to allow for sticky header */
  .home-page,
  .profile-page {
    margin-top: 74px !important;

    @media (min-width: $tfl-mobile-view + 1) {
      margin-top: 94px !important;
    }
  }
}

.tfl-page-header {
  margin-top: 80px;
}

.tfl-footer {
  position: sticky;
  top: 100vh;
}

@media (max-width: 991px) {
  .tfl-go-card-desktop {
    display: none;
  }
  .tfl-go-card-mobile {
    display: block;
  }
}

//Bootstrap overrides
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 100%;
    padding-right: 16px;
    padding-left: 16px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 100%;
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 100%;
  }
}

@media (min-width: 1320px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1440px;
  }
}

@media (min-width: 1480px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1440px;
    padding-right: 0;
    padding-left: 0;
  }
}

/* Overflow scrolling styles for chrome and IE and safari */
/* Width */
.tfl-header__outer-wrapper--mobile-menu-shown::-webkit-scrollbar {
  width: 5px;
}
/* Track */
.tfl-header__outer-wrapper--mobile-menu-shown::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
.tfl-header__outer-wrapper--mobile-menu-shown::-webkit-scrollbar-thumb {
  background: $tfl-white;
  border-radius: $tfl-border-radius;
}
/* Handle on hover */
.tfl-header__outer-wrapper--mobile-menu-shown::-webkit-scrollbar-thumb:hover {
  background: $tfl-white;
}
