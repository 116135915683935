@import '@tfl/component-library/build/assets/scss/common.scss';

.tfl-personal-data-header {
  margin-bottom: 13px;
}
.tfl-personal-data {
  &__list-header {
    margin-bottom: 16px;
  }

  &__list {
    padding-left: 0;
    list-style: none;
    li {
      margin-bottom: 16px;
      line-height: 26px;
      padding-left: 1em;
      position: relative;
      &:after {
        content: '';
        height: 0.2em;
        width: 0.2em;
        background: $tfl-black;
        display: block;
        position: absolute;
        transform: rotate(45deg);
        top: 11px;
        left: 0;
      }
    }
  }
}

.tfl-last-button {
  margin-bottom: 50px;
}
