@import '@tfl/component-library/build/assets/scss/common.scss';
@import '/src/assets/scss/mixin.scss';

.tfl-dashboard {
  &__GoCard {
    background-color: $tfl-waterloo-city-line-teal;
    border-radius: $tfl-border-radius;
    padding: 24px 16px 16px 16px;

    &-header {
      font-family: $johnston-medium;
      font-size: 26px;
      padding: 0 13px 16px 0;
    }

    &-label-wrapper {
      margin-bottom: 24px;
    }

    &-description {
      font-family: $johnston-light;
      font-size: 18px;
      min-height: 47px;
      padding: 0 13px 0 0;
    }

    &-badge-wrapper {
      display: flex;
      align-items: center;
    }

    &-appleBadge {
      &--icon-apple-badge {
        @include icon-apple-badge;
        background-size: contain;
        cursor: pointer;
        width: 173px;
        height: 58px;
        margin-right: 16px;
      }
    }

    &-androidBadge {
      &--icon-android-badge {
        @include icon-android-badge;
        background-size: 100%;
        cursor: pointer;
        width: 192px;
        height: 58px;
      }
    }
  }
}
